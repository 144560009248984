<script>
import TablePage from '../../../../../components/table_page';
import request from '../../../../../utils/request';
import Form from './form';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      requestUrl: '/sfa/sfaVisitPlanController/list',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {
    this.getConfigList('sfaCenter_visitManage_visitPlanFormulate_list');
  },
  methods: {
    cellClick({ row, column }) {
      if (column.property === 'visitRealName') {
        this.formConfig = {
          type: 'view',
          id: row.id,
        };
        this.modalConfig.title = '查看';
        this.openModal();
      }
    },
    modalClick({ val, row }) {
      this.formName = 'Form';
      this.formConfig = {};
      let urls = '';
      let text = '';
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.openModal();
      } else if (val.code === 'edit') {
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.modalConfig.title = '编辑';
        this.openModal();
      } else if (val.code === 'view') {
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.modalConfig.title = '查看';
        this.openModal();
      } else if (val.code === 'delete') {
        urls = '/sfa/sfaVisitPlanController/delete';
        text = '删除';
        this.operationClick(urls, text);
      } else if (val.code === 'enable') {
        urls = '/sfa/sfaVisitPlanController/enable';
        text = '启用';
        this.operationClick(urls, text);
      } else if (val.code === 'disable') {
        urls = '/sfa/sfaVisitPlanController/disable';
        text = '禁用';
        this.operationClick(urls, text);
      } else if (val.code === 'rute_group_add') {
        this.formConfig = {
          type: val.code,
        };
        this.modalConfig.title = '按线路组新增';
        this.openModal();
      } else if (val.code === 'outlets_add') {
        this.formConfig = {
          type: val.code,
        };
        this.modalConfig.title = '按网点新增';
        this.openModal();
      } else if (val.code === 'frequency_add') {
        this.formConfig = {
          type: val.code,
        };
        this.modalConfig.title = '按频率新增';
        this.openModal();
      // } else if (val.code === 'manually_refresh') {
      //   const title = '手动刷新后，会重置本规则未来拜访信息，请确认执行手动刷新吗？';
      //   urls = '/sfa/sfaVisitPlanController/produceSfaVisitRule';
      //   text = '生成拜访计划规则';
      //   if (this.selection.length > 0) {
      //     this.$confirm(title, '提示', {
      //       confirmButtonText: '确定',
      //       cancelButtonText: '取消',
      //       type: 'warning',
      //     })
      //       .then(() => {
      //         this.operationClick(urls, text, 'ids');
      //       })
      //       .catch(() => {
      //         this.$message({
      //           type: 'info',
      //           message: '已取消',
      //         });
      //       });
      //   } else {
      //     this.$message({
      //       type: 'error',
      //       message: '请选择数据',
      //     });
      //   }
      }
    },
    operationClick(urls, text, type) {
      let datas = null;
      if (this.selection.length > 0) {
        if (type === 'ids') {
          const obj = {
            ids: this.selection,
          };
          datas = obj;
        } else {
          datas = this.selection;
        }
        request.post(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.selection = [];
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
  },
};
</script>
