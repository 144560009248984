<template>
  <div>
    <el-form>
      <div>基本信息</div>
      <el-row :gutter="24">
        <el-col :md="8">
          <el-form-item class="flex" label="人员姓名">
            <el-select
              :disabled="disabled"
              v-model="value.visitRealName"
              filterable
              remote
              reserve-keyword
              placeholder="请输入人员姓名模糊搜索"
              :remote-method="(query)=>{remoteMethod(query,'person')}"
              @change="getChange($event, 'person')"
              :loading="loading">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="(item.orgName || '') + '/' + (item.positionName || '') + '/' + (item.fullName || '')"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item class="flex" label="人员职位">
            <el-input disabled v-model="value.visitPosName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item class="flex" label="所属组织">
            <el-input disabled v-model="value.visitOrgName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div>拜访信息</div>
      <el-row :gutter="24">
        <el-col :md="12">
          <el-form-item class="flex" label="循环日期">
            <el-date-picker :disabled="disabled" @change="getDate" v-model="value.date" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" v-if="(routeType === 'line') || (value.routeType === 'line')">
        <el-col :md="8">
          <el-form-item label="循环路线组">
            <el-button type="primary" size="small" @click="addLine" v-if="!disabled">新增</el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <template v-if="(routeType === 'line') || (value.routeType === 'line')">
        <el-row :gutter="24" v-for="(item, index) in value.sfaVisitPlanRangeReqVos" :key="index">
          <el-col :md="8">
            <el-form-item class="flex" :label="`线路组${index + 1}`">
              <el-select
                v-model="item.visitGroupName"
                filterable
                :disabled="disabled"
                remote
                reserve-keyword
                placeholder="请输入线路组模糊搜索"
                :remote-method="(query)=>{remoteMethod(query,'line', index)}"
                @change="getChange($event, 'line', index)"
                :loading="loading">
                <el-option
                  v-for="v in item.options"
                  :key="v.visitGroupId"
                  :label="v.visitGroupName"
                  :value="v.visitGroupId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-button class="ml20" v-if="(index !== 0) && !disabled" @click="delLine(index)">删除</el-button>
          </el-col>
        </el-row>
      </template>
      <el-row :gutter="24">
        <el-col :md="18">
          <el-form-item class="flex" label="排除">
          <el-checkbox-group v-model="checkList" :disabled="disabled" @change="getCheckBox">
            <el-checkbox label="1">星期一</el-checkbox>
            <el-checkbox label="2">星期二</el-checkbox>
            <el-checkbox label="3">星期三</el-checkbox>
            <el-checkbox label="4">星期四</el-checkbox>
            <el-checkbox label="5">星期五</el-checkbox>
            <el-checkbox label="6">星期六</el-checkbox>
            <el-checkbox label="7">星期日</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" v-if="(routeType === 'outlets') || (value.routeType === 'outlets') || (routeType === 'rate') || (value.routeType === 'rate')">
        <el-form-item class="flex" label="拜访客户" required>
          <el-col :md="8" v-if="!disabled">
            <el-button type="primary" @click="getDialog('customer')">添加经销商</el-button>
            <el-button type="primary" @click="getDialog('deport')">添加终端</el-button>
            <el-button @click="delTable">删除</el-button>
          </el-col>
        </el-form-item>
      </el-row>
    </el-form>
    <vxe-table :align="allAlign"
                 v-if="(routeType === 'outlets') || (value.routeType === 'outlets') || (routeType === 'rate') || (value.routeType === 'rate')"
                ref="xTable"
    @checkbox-all="({records}) => selectAllEvent(records)"
               @checkbox-change="({records}) => selectChangeEvent(records)"
               :edit-config="{trigger: 'click', mode: 'cell', activeMethod: activeCellMethod}"
               :data="value.sfaVisitclientRelRespVos">
      <vxe-table-column type="checkbox"
                        width="50"></vxe-table-column>
      <vxe-table-column field="clientCode" title="客户编码" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="clientName" title="客户名称" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="clientTypeName" title="客户类型" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="clientSubclassName" title="客户细类" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="clientContacts" title="联系人" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="clientAddress" title="客户地址" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="firstVisitDate" title="首次拜访日期" :edit-render="{name: '$input', props: {type: 'date'}}" v-if="(routeType === 'rate') || (value.routeType === 'rate')" width="120"></vxe-table-column>
      <vxe-table-column field="visitFrequency" title="拜访频率（天/1次）" :edit-render="{name: '$input', props: {type: 'number'}}" v-if="(routeType === 'rate') || (value.routeType === 'rate')" width="160"></vxe-table-column>
    </vxe-table>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect"/>
  </div>
</template>
<script>
import request from '../../../../../../../utils/request';
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  props: {
    value: Object,
    routeType: String,
    isEdit: Boolean,
    disabled: Boolean,
  },
  components: {
    SelectConfig,
  },
  data() {
    return {
      checkList: [],
      selectionList: [],
      options: [],
      loading: false,
      allAlign: null,
    };
  },
  created() {
    if (!this.isEdit || !this.disabled) {
      this.value.routeType = this.routeType;
      this.value.sfaVisitPlanRangeReqVos = [];
      this.value.checkList = [];
      this.value.sfaVisitPlanRangeReqVos.push({
        visitGroupName: '',
        visitGroupId: '',
        options: [],
      });
    }
    this.getClientType();
    this.getNames();
    this.$emit('input', this.value);
  },
  watch: {
    value(val) {
      this.checkList = val.checkList;
    },
  },
  methods: {
    // 数据字典获取客户类型
    getClientType() {
      request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'sfacusType' }).then((res) => {
        console.log(res);
        if (res.success && res.result) {
          const clientType = {};
          res.result.map((v) => {
            clientType[v.dictCode] = v.dictValue;
            return v;
          });
          this.clientType = clientType;
        }
      });
    },
    // 查看时禁用表格编辑
    activeCellMethod({ columnIndex }) {
      if (this.disabled && (columnIndex === 6 || columnIndex === 7)) {
        return false;
      }
      return true;
    },
    // 人员姓名远程搜索
    remoteMethod(query, type, index) {
      this.loading = true;
      if (type === 'person') {
        this.getNames(query);
      } else if (this.value.visitUserName) {
        this.getLines(query, index, this.value.visitUserName);
      } else {
        this.loading = false;
        this.$message.error('请先选择人员姓名');
      }
    },
    // 获取人员姓名数据
    getNames(unionName) {
      const datas = {
        enableStatus: '009',
        // allUnderThisPositionCode: JSON.parse(localStorage.getItem('userInfo')).positionCode,
        includeEmptyUserPosition: false,
        unionName,
      };
      request.post('/mdm/mdmPositionController/positionSelectList', datas, { headers: { functionCode: 'select-position' } }).then((res) => {
        if (res.success) {
          this.options = res.result || [];
          this.loading = false;
        }
      });
    },
    // 获取线路组数据
    getLines(groupName, index, userName) {
      request.post('/sfa/sfaVisitGroupController/list', { groupName, userName, enableStatus: '009' }).then((res) => {
        if (res.success) {
          const options = res.result.data;
          options.map((v) => {
            const item = v;
            item.visitGroupName = item.groupName;
            item.visitGroupId = item.id;
            return options;
          });
          this.value.sfaVisitPlanRangeReqVos[index].options = options || [];
          this.$forceUpdate();
          this.loading = false;
        }
      });
    },
    // 新增
    addLine() {
      if (this.value.visitUserName) {
        this.value.sfaVisitPlanRangeReqVos.push({
          visitGroupName: '',
          visitGroupId: '',
          options: [],
        });
        const index = this.value.sfaVisitPlanRangeReqVos.length - 1;
        this.loading = true;
        this.getLines(null, index, this.value.visitUserName);
      } else {
        this.loading = false;
        this.$message.error('请先选择人员姓名');
      }
    },
    // 删除
    delLine(index) {
      this.value.sfaVisitPlanRangeReqVos.splice(index, 1);
      this.$forceUpdate();
      this.$emit('input', this.value);
    },
    // 人员姓名选中后
    getChange(e, type, index) {
      if (type === 'person') {
        const optionsIndex = this.options.findIndex((val) => val.id === e);
        if (optionsIndex > -1) {
          this.value.visitPosName = this.options[optionsIndex].positionName;
          this.value.visitPosCode = this.options[optionsIndex].positionCode;
          this.value.visitOrgName = this.options[optionsIndex].orgName;
          this.value.visitRealName = this.options[optionsIndex].fullName;
          this.value.visitOrgCode = this.options[optionsIndex].orgCode;
          this.value.visitUserName = this.options[optionsIndex].userName;
          this.value.sfaVisitPlanRangeReqVos = [{
            visitGroupName: '',
            visitGroupId: '',
            options: [],
          }];
          this.getLines(null, 0, this.value.visitUserName);
        }
      } else {
        const lineIndex = this.value.sfaVisitPlanRangeReqVos.findIndex((val) => val.visitGroupId === e);
        if (lineIndex === -1) {
          const optionsIndex = this.value.sfaVisitPlanRangeReqVos[index].options.findIndex((val) => val.visitGroupId === e);
          this.value.sfaVisitPlanRangeReqVos[index].visitGroupId = e;
          this.value.sfaVisitPlanRangeReqVos[index].visitGroupName = this.value.sfaVisitPlanRangeReqVos[index].options[optionsIndex].visitGroupName;
          this.$forceUpdate();
        } else {
          this.$message.error('该路线已选，请重新选择一条路线');
        }
      }
      this.$emit('input', this.value);
    },
    getDialog(type) {
      this.clickType = type;
      if (this.value.visitPosCode) {
        const params = {
          // 添加经销商
          functionCode: 'sfaCenter_visitManage_visitPlanFormulate_list2',
          data: [],
          idKey: 'id',
          paramData: { positionCode: this.value.visitPosCode },
        };
        // 添加终端
        if (type === 'deport') {
          params.functionCode = 'sfaCenter_visitManage_visitPlanFormulate_list1';
        }
        this.$refs.selectConfig.openSelectModal(params);
      } else {
        // 添加经销商
        this.$message.error('请先选择人员姓名');
      }
    },
    onGetSelect(val) {
      const result = val;
      const arr = [];
      result.map((v) => {
        const item = v;
        // arr.push({
        //   clientCode: item.terminalCode || item.customerCode,
        //   clientName: item.terminalName || item.customerName,
        //   clientType: item.terminalType || item.customerType,
        //   // 客户类型
        //   clientTypeName: item.customerTypeName || item.terminalTypeName,
        //   terminalType: item.terminalType || '',
        //   customerType: item.customerType || '',
        //   clientAddress: item.terminalAddress || item.registeredAddress,
        //   clientContacts: item.contactName,
        //   clientId: item.id,
        //   clientPhoto: item.shopImagePath,
        //   clientPhone: item.contactPhone,
        //   createCode: item.createCode,
        //   latitude: item.latitude,
        //   longitude: item.longitude,
        //   visitFrequency: null,
        //   firstVisitDate: null,
        // });
        if (this.clickType === 'customer') {
          arr.push({
            clientCode: item.customerCode,
            clientName: item.customerName,
            clientType: 'dealer',
            clientTypeName: this.clientType.dealer,
            clientSubclass: item.customerType,
            clientSubclassName: item.customerTypeName,
            clientAddress: item.registeredAddress,
            clientContacts: item.contactName,
            clientId: item.id,
            clientPhoto: item.shopImagePath,
            clientPhone: item.contactPhone,
            createCode: item.createCode,
            latitude: item.latitude,
            longitude: item.longitude,
          });
        } else {
          arr.push({
            clientCode: item.terminalCode,
            clientName: item.terminalName,
            clientType: 'terminal',
            clientTypeName: this.clientType.terminal,
            clientSubclass: item.terminalType,
            clientSubclassName: item.terminalTypeName,
            clientAddress: item.terminalAddress,
            clientContacts: item.contactName,
            clientId: item.id,
            clientPhoto: item.shopImagePath,
            clientPhone: item.contactPhone,
            createCode: item.createCode,
            latitude: item.latitude,
            longitude: item.longitude,
          });
        }
        return item;
      });
      this.$set(this.value, 'sfaVisitclientRelRespVos', this.value.sfaVisitclientRelRespVos ? this.getReset(this.value.sfaVisitclientRelRespVos, arr, 'clientId') : arr);
      this.value.checkList = this.checkList;
      this.$emit('input', this.value);
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      list1.forEach((v1) => {
        let notRepeat = true;
        const rowData = v1;
        for (const v2 in list2) {
          if (v1[key] === list2[v2][key]) {
            notRepeat = true;
            return false;
          }
        }
        if (notRepeat) {
          list.push(rowData);
        }
        return rowData;
      });
      return list;
    },
    delTable() {
      if (this.selectionList.length) {
        this.selectionList.map((v) => {
          const item = v;
          const index = this.value.sfaVisitclientRelRespVos.findIndex((val) => val.clientId === item.clientId);
          if (index > -1) {
            this.value.sfaVisitclientRelRespVos.splice(index, 1);
            this.selectionList = [];
            this.$set(this.value, 'sfaVisitclientRelRespVos', this.value.sfaVisitclientRelRespVos);
            this.$forceUpdate();
          }
          return item;
        });
      } else {
        this.$message.error('请选择数据');
      }
    },
    selectChangeEvent(records) {
      this.selectionList = records;
    },
    selectAllEvent(records) {
      this.selectionList = records;
    },
    // 排除
    getCheckBox() {
      this.value.notWeekSet = this.checkList.join(',');
      this.$emit('input', this.value);
    },
    // 循环日期
    getDate(e) {
      const visitEndDate = e[1];
      const visitStartDate = e[0];
      this.value.visitEndDate = visitEndDate;
      this.value.visitStartDate = visitStartDate;
      this.$emit('input', this.value);
    },
  },
};
</script>
<style lang="less" scoped>
  .flex{
    display: flex;
  }
  .el-row{
    margin: 0!important;
  }
  /deep/ .el-form-item__label{
    width: 125px;
  }
  .el-col{
    padding: 0!important;
  }
  .ml20{
    margin-left: 20px;
  }
</style>
<style lang="less">
  //日期框
  .vxe-input--panel.type--date, .vxe-input--panel.type--month, .vxe-input--panel.type--week, .vxe-input--panel.type--year {
    z-index: 9998 !important
  }
      //选择框
  .vxe-select--panel {
    z-index: 9997 !important
  }
</style>
