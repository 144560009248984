var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _c("div", [_vm._v("基本信息")]),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "人员姓名" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "请输入人员姓名模糊搜索",
                            "remote-method": function (query) {
                              _vm.remoteMethod(query, "person")
                            },
                            loading: _vm.loading,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getChange($event, "person")
                            },
                          },
                          model: {
                            value: _vm.value.visitRealName,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "visitRealName", $$v)
                            },
                            expression: "value.visitRealName",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label:
                                (item.orgName || "") +
                                "/" +
                                (item.positionName || "") +
                                "/" +
                                (item.fullName || ""),
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "人员职位" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.value.visitPosName,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "visitPosName", $$v)
                          },
                          expression: "value.visitPosName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "所属组织" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.value.visitOrgName,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "visitOrgName", $$v)
                          },
                          expression: "value.visitOrgName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", [_vm._v("拜访信息")]),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "循环日期" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: _vm.disabled,
                          "value-format": "yyyy-MM-dd",
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        on: { change: _vm.getDate },
                        model: {
                          value: _vm.value.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "date", $$v)
                          },
                          expression: "value.date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.routeType === "line" || _vm.value.routeType === "line"
            ? _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "循环路线组" } },
                        [
                          !_vm.disabled
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.addLine },
                                },
                                [_vm._v("新增")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.routeType === "line" || _vm.value.routeType === "line"
            ? _vm._l(_vm.value.sfaVisitPlanRangeReqVos, function (item, index) {
                return _c(
                  "el-row",
                  { key: index, attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex",
                            attrs: { label: "线路组" + (index + 1) },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  disabled: _vm.disabled,
                                  remote: "",
                                  "reserve-keyword": "",
                                  placeholder: "请输入线路组模糊搜索",
                                  "remote-method": function (query) {
                                    _vm.remoteMethod(query, "line", index)
                                  },
                                  loading: _vm.loading,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.getChange($event, "line", index)
                                  },
                                },
                                model: {
                                  value: item.visitGroupName,
                                  callback: function ($$v) {
                                    _vm.$set(item, "visitGroupName", $$v)
                                  },
                                  expression: "item.visitGroupName",
                                },
                              },
                              _vm._l(item.options, function (v) {
                                return _c("el-option", {
                                  key: v.visitGroupId,
                                  attrs: {
                                    label: v.visitGroupName,
                                    value: v.visitGroupId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        index !== 0 && !_vm.disabled
                          ? _c(
                              "el-button",
                              {
                                staticClass: "ml20",
                                on: {
                                  click: function ($event) {
                                    return _vm.delLine(index)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              })
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 18 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "排除" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          attrs: { disabled: _vm.disabled },
                          on: { change: _vm.getCheckBox },
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        [
                          _c("el-checkbox", { attrs: { label: "1" } }, [
                            _vm._v("星期一"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "2" } }, [
                            _vm._v("星期二"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "3" } }, [
                            _vm._v("星期三"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "4" } }, [
                            _vm._v("星期四"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "5" } }, [
                            _vm._v("星期五"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "6" } }, [
                            _vm._v("星期六"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "7" } }, [
                            _vm._v("星期日"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.routeType === "outlets" ||
          _vm.value.routeType === "outlets" ||
          _vm.routeType === "rate" ||
          _vm.value.routeType === "rate"
            ? _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "拜访客户", required: "" },
                    },
                    [
                      !_vm.disabled
                        ? _c(
                            "el-col",
                            { attrs: { md: 8 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDialog("customer")
                                    },
                                  },
                                },
                                [_vm._v("添加经销商")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDialog("deport")
                                    },
                                  },
                                },
                                [_vm._v("添加终端")]
                              ),
                              _c("el-button", { on: { click: _vm.delTable } }, [
                                _vm._v("删除"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.routeType === "outlets" ||
      _vm.value.routeType === "outlets" ||
      _vm.routeType === "rate" ||
      _vm.value.routeType === "rate"
        ? _c(
            "vxe-table",
            {
              ref: "xTable",
              attrs: {
                align: _vm.allAlign,
                "edit-config": {
                  trigger: "click",
                  mode: "cell",
                  activeMethod: _vm.activeCellMethod,
                },
                data: _vm.value.sfaVisitclientRelRespVos,
              },
              on: {
                "checkbox-all": function (ref) {
                  var records = ref.records

                  return _vm.selectAllEvent(records)
                },
                "checkbox-change": function (ref) {
                  var records = ref.records

                  return _vm.selectChangeEvent(records)
                },
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { type: "checkbox", width: "50" },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "clientCode",
                  title: "客户编码",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "clientName",
                  title: "客户名称",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "clientTypeName",
                  title: "客户类型",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "clientSubclassName",
                  title: "客户细类",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "clientContacts",
                  title: "联系人",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "clientAddress",
                  title: "客户地址",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _vm.routeType === "rate" || _vm.value.routeType === "rate"
                ? _c("vxe-table-column", {
                    attrs: {
                      field: "firstVisitDate",
                      title: "首次拜访日期",
                      "edit-render": {
                        name: "$input",
                        props: { type: "date" },
                      },
                      width: "120",
                    },
                  })
                : _vm._e(),
              _vm.routeType === "rate" || _vm.value.routeType === "rate"
                ? _c("vxe-table-column", {
                    attrs: {
                      field: "visitFrequency",
                      title: "拜访频率（天/1次）",
                      "edit-render": {
                        name: "$input",
                        props: { type: "number" },
                      },
                      width: "160",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }