<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import formAdd from './components';

formCreate.component('formAdd', formAdd);

export default {
  extends: Form,
  components: {
    // Modal,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const rule = await this.getBeforFormRule('sfaCenter_visitManage_visitPlanFormulate_add');
    const newRule = [];
    rule.forEach((item) => {
      const v = item;
      if (v.field === 'formAdd') {
        if (this.formConfig.type === 'rute_group_add') {
          this.pushArr(newRule, 'line');
        } else if (this.formConfig.type === 'outlets_add') {
          this.pushArr(newRule, 'outlets');
        } else if (this.formConfig.type === 'frequency_add') {
          this.pushArr(newRule, 'rate');
        } else if (this.formConfig.type === 'edit') {
          this.pushArr(newRule, null, { isEdit: true });
        } else if (this.formConfig.type === 'view') {
          this.pushArr(newRule, null, { disabled: true });
          this.buttons.submit = false;
        } else {
          this.pushArr(newRule, null);
        }
      } else {
        newRule.push(v);
      }
    });

    this.rule = newRule;
    this.reload(this.rule);
    if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
      request
        .get('/sfa/sfaVisitPlanController/queryDetailById', {
          id: this.formConfig.id,
        })
        .then((res) => {
          if (res.success) {
            const { result } = res;
            result.date = [result.visitStartDate, result.visitEndDate];
            result.checkList = result.notWeekSet ? result.notWeekSet.split(',') : [];
            if (result.routeType !== 'line') {
              // result.sfaVisitPlanRangeRespVos.map((v) => {
              //   const item = v;
              //   item.clientTypeName = item.clientTypeDesc;
              //   return item;
              // });
              result.sfaVisitclientRelRespVos = result.sfaVisitPlanRangeRespVos;
            } else {
              result.sfaVisitPlanRangeReqVos = result.sfaVisitPlanRangeRespVos;
            }
            const data = {
              form_add: result,
            };
            this.setValue(data);
            this.row = result;
          }
        });
    }
  },
  methods: {
    pushArr(newRule, routeType, obj) {
      return newRule.push({
        type: 'formAdd',
        field: 'form_add',
        title: '',
        value: {},
        props: {
          routeType,
          ...obj,
        },
      });
    },
    // 提交
    submit() {
      const formData = this.getFormData().form_add;
      if (formData.sfaVisitclientRelRespVos) {
        const arr = [];
        formData.sfaVisitclientRelRespVos.map((v) => {
          const item = v;
          if (item.terminalType === '2') {
            item.terminalTypeStr = 'batchDealer';
          } else if (item.terminalType === '1') {
            item.terminalTypeStr = 'terminal';
          } else {
            item.terminalTypeStr = '';
          }
          if (item.customerType) {
            item.customerTypeStr = 'dealer';
          }
          arr.push({
            ...item,
            // clientCode: item.clientCode,
            // clientName: item.clientName,
            clientType: item.terminalTypeStr || item.customerTypeStr || item.clientType,
            // clientAddress: item.clientAddress,
            // clientContacts: item.clientContacts,
            // clientId: item.clientId,
            // clientPhoto: item.clientPhoto,
            // clientPhone: item.clientPhone,

          });
          return item;
        });
        formData.sfaVisitPlanRangeReqVos = arr;
      }
      console.log(formData);
      if (formData) {
        let url = '/sfa/sfaVisitPlanController/save';
        let params = { ...formData };

        if (this.formConfig.type === 'edit') {
          url = '/sfa/sfaVisitPlanController/update';
          params = Object.assign(this.row, formData);
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });

            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
